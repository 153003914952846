// App.tsx
import React, { useState, useEffect } from 'react';
import { Container, CssBaseline, Typography, Tabs, Tab, Box, AppBar, Toolbar, Button } from '@mui/material';
import AccordionComponent from './components/AccordionComponent';
import ApiDialog from './components/ApiDialog'; // Adjust the path as necessary
import Dashboard from './components/Dashboard'; // Import Dashboard component
import LoginDialog from './components/LoginDialog'; // Import LoginDialog component

const App: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [dialogs, setDialogs] = useState<Array<{ id: number; endpoint: string; fields: Array<{ label: string; name: string; type: string }> }>>([]);
  const [dialogCounter, setDialogCounter] = useState(0);
  const [token, setToken] = useState<string | null>(null);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);

  useEffect(() => {
    // Check for token in localStorage on mount
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    } else {
      setLoginOpen(true); // Open login dialog if no token found
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const openDialog = (endpoint: string) => {
    let fields: Array<{ label: string; name: string; type: string }> = [];

    switch (endpoint) {
      case 'search_embeddings_by_url':
        fields = [{ label: 'URL', name: 'url', type: 'text' }];
        break;
      case 'perform_moderation_by_url':
        fields = [
          { label: 'URL', name: 'url', type: 'text' },
          { label: 'Regenerate', name: 'regenerate', type: 'checkbox' },
          { label: 'Training Set Name (optional)', name: 'training_set_name', type: 'text' },
        ];
        break;
      case 'search_embeddings':
        fields = [{ label: 'Text', name: 'text', type: 'text' }];
        break;
      case 'scrape_url':
        fields = [{ label: 'URL', name: 'url', type: 'text' }];
        break;
      case 'create_embeddings_from_training_set':
        fields = [{ label: 'Training Set Name', name: 'training_set_name', type: 'text' }];
        break;
      case 'reset_embeddings':
        fields = []; // No fields needed for reset_embeddings
        break;
      default:
        break;
    }

    setDialogs([...dialogs, { id: dialogCounter, endpoint, fields }]);
    setDialogCounter(dialogCounter + 1);
  };

  const closeDialog = (id: number) => {
    setDialogs(dialogs.filter((dialog) => dialog.id !== id));
  };

  const handleLoginSuccess = (newToken: string) => {
    setToken(newToken);
    localStorage.setItem('token', newToken); // Store token in localStorage
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
    setLoginOpen(true); // Re-open login dialog
  };

  return (
    <Container>
      <CssBaseline />
      <Typography variant="h4" gutterBottom>
        AI Moderation Test Application
      </Typography>
      <AppBar position="static" style={{ marginBottom: '16px' }}>
        <Toolbar>
          {token && (
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="Tabs">
        <Tab label="Moderation App" />
        <Tab label="REST Client" />
        <Tab label="Dashboard" /> {/* New Dashboard tab */}
      </Tabs>
      <Box hidden={selectedTab !== 0} p={3}>
        <AppBar position="static">
          <Toolbar>
            <Button color="inherit" onClick={() => openDialog('search_embeddings_by_url')}>
              AI Moderate URL
            </Button>
            <Button color="inherit" onClick={() => openDialog('perform_moderation_by_url')}>
              Moderate URL
            </Button>
            <Button color="inherit" onClick={() => openDialog('search_embeddings')}>
              Search Embeddings
            </Button>
            <Button color="inherit" onClick={() => openDialog('scrape_url')}>
              Scrape URL
            </Button>
            <Button color="inherit" onClick={() => openDialog('create_embeddings_from_training_set')}>
              Create Embeddings
            </Button>
            <Button color="inherit" onClick={() => openDialog('reset_embeddings')}>
              Reset Embeddings
            </Button>
          </Toolbar>
        </AppBar>

        {dialogs.map((dialog) => (
          <ApiDialog
            key={dialog.id}
            id={dialog.id}
            endpoint={dialog.endpoint}
            fields={dialog.fields}
            onClose={closeDialog}
            token={token} // Pass the token to ApiDialog
          />
        ))}
        <AccordionComponent token={token} /> {/* Pass the token to AccordionComponent */}
      </Box>
      <Box hidden={selectedTab !== 1} p={3}>
        <AccordionComponent token={token} />
      </Box>
      <Box hidden={selectedTab !== 2} p={3}> {/* Dashboard tab content */}
        <Dashboard token={token} /> {/* Pass the token to Dashboard */}
      </Box>

      {/* Login Dialog */}
      <LoginDialog open={loginOpen} onClose={() => setLoginOpen(false)} onLoginSuccess={handleLoginSuccess} />
    </Container>
  );
};

export default App;
