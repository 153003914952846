// FormComponent.tsx
import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import config from '../config';

interface FormComponentProps {
  endpoint: string;
  fields: { name: string; type: string }[];
  token: string | null; // Accept token as prop
}

const FormComponent: React.FC<FormComponentProps> = ({ endpoint, fields, token }) => {
  const [payload, setPayload] = useState<{ [key: string]: any }>({});
  const [response, setResponse] = useState<string>('');

  const handleChange = (name: string, value: any) => {
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      // Prepare the payload
      const preparedPayload = { ...payload };
      fields.forEach((field) => {
        if (field.type === 'json' && typeof preparedPayload[field.name] === 'string') {
          try {
            preparedPayload[field.name] = JSON.parse(preparedPayload[field.name]);
          } catch (e) {
            setResponse(`Error: Invalid JSON format in field ${field.name}`);
            return;
          }
        }
      });

      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        },
        body: JSON.stringify(preparedPayload),
      });
      const result = await res.json();
      setResponse(JSON.stringify(result, null, 2));
    } catch (error) {
      if (error instanceof Error) {
        setResponse('Error: ' + error.message);
      } else {
        setResponse('An unexpected error occurred');
      }
    }
  };

  return (
    <div>
      {fields.map((field, index) => (
        <TextField
          key={index}
          label={field.name}
          type={field.type === 'json' ? 'text' : field.type}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => handleChange(field.name, field.type === 'number' ? +e.target.value : e.target.value)}
        />
      ))}
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
      {response && (
        <Typography variant="body2" color="textSecondary" component="pre">
          {response}
        </Typography>
      )}
    </div>
  );
};

export default FormComponent;
