// ApiDialog.tsx
import React, { useState } from 'react';
import { Paper, IconButton, Box, TextField, CircularProgress, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';

interface ApiDialogProps {
  id: number;
  endpoint: string;
  fields: Array<{ label: string; name: string; type: string }>;
  onClose: (id: number) => void;
  token: string | null; // Accept token as prop
}

const ApiDialog: React.FC<ApiDialogProps> = ({ id, endpoint, fields, onClose, token }) => {
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async () => {
    console.log('Submitted data:', formData);
    setLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${endpoint}`;
      console.log('API URL:', apiUrl);

      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        },
        body: JSON.stringify(formData),
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.detail || res.statusText);
      }

      const result = await res.json();
      console.log('Response:', result);

      setResponse(result);
    } catch (error: any) {
      console.error('Error:', error);
      setResponse({ error: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose(id);
  };

  return (
    <Draggable handle=".handle">
      <Resizable
        defaultSize={{
          width: 600,
          height: 400,
        }}
        minHeight={200}
        minWidth={300}
        style={{ position: 'absolute', top: '100px', left: '100px' }}
      >
        <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="handle"
            style={{ cursor: 'move', padding: '16px', borderBottom: '1px solid #ddd' }}
          >
            <strong>{`API Request: ${endpoint}`}</strong>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box style={{ padding: '16px', flexGrow: 1, overflowY: 'auto' }}>
            {!response ? (
              <form noValidate autoComplete="off">
                {fields.map((field) =>
                  field.type === 'checkbox' ? (
                    <FormControlLabel
                      key={field.name}
                      control={
                        <Checkbox
                          name={field.name}
                          checked={!!formData[field.name]}
                          onChange={handleInputChange}
                        />
                      }
                      label={field.label}
                    />
                  ) : (
                    <TextField
                      key={field.name}
                      label={field.label}
                      name={field.name}
                      type={field.type}
                      fullWidth
                      margin="normal"
                      onChange={handleInputChange}
                    />
                  )
                )}
                {!loading ? (
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {fields.length > 0 ? 'Submit' : 'Confirm'}
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </form>
            ) : (
              <Box style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                {JSON.stringify(response, null, 2)}
              </Box>
            )}
          </Box>
          {response && (
            <Box display="flex" justifyContent="flex-end" padding="16px" borderTop="1px solid #ddd">
              <Button variant="contained" color="primary" onClick={handleClose}>
                Done
              </Button>
            </Box>
          )}
        </Paper>
      </Resizable>
    </Draggable>
  );
};

export default ApiDialog;
