// Dashboard.tsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import config from '../config'; // Assuming config contains the base API URL

interface ScrapeLog {
  id: number;
  job: string;
  url: string;
  log_data: string;
  scrape_ok: boolean;
  createdAt: string;
}

interface DashboardProps {
  token: string | null;
}

const Dashboard: React.FC<DashboardProps> = ({ token }) => {
  const [dashboardData, setDashboardData] = useState<ScrapeLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedLogData, setSelectedLogData] = useState<string | null>(null);

  const fetchDashboardSummary = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiBaseUrl}/get_dashboard_summary`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setDashboardData(data.recent_scrape_logs || []);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenLogData = (logData: string) => {
    setSelectedLogData(logData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedLogData(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'job', headerName: 'Job', width: 150 },
    { field: 'url', headerName: 'URL', width: 250 },
    { field: 'scrape_ok', headerName: 'Scrape OK', width: 150, type: 'boolean' },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
    {
      field: 'log_data',
      headerName: 'Log Data',
      width: 200,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleOpenLogData(params.row.log_data)}>
          View Log...
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchDashboardSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Dashboard
      </Typography>
      <Button variant="contained" color="primary" onClick={fetchDashboardSummary} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Refresh'}
      </Button>
      <Box mt={3} style={{ height: 400, width: '100%' }}>
        {dashboardData.length ? (
          <DataGrid
            rows={dashboardData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5 },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick // Use the correct prop name
          />
        ) : (
          <Typography variant="body1">No data available. Click refresh to load data.</Typography>
        )}
      </Box>

      {/* Dialog for displaying full log_data */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Log Data</DialogTitle>
        <DialogContent>
          <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
            {selectedLogData}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
