// AccordionComponent.tsx
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormComponent from './FormComponent';

interface Endpoint {
  name: string;
  url: string;
  fields: Array<{ name: string; type: string }>;
}

interface AccordionComponentProps {
  token: string | null;
}

const endpoints: Endpoint[] = [
  { name: 'Create Embedding', url: '/create_embedding', fields: [{ name: 'text', type: 'text' }, { name: 'categories', type: 'json' }] },
  { name: 'Perform Moderation', url: '/perform_moderation', fields: [{ name: 'scrape_id', type: 'number' }] },
  { name: 'Search Embeddings', url: '/search_embeddings', fields: [{ name: 'text', type: 'text' }] },
  { name: 'Index All URLs', url: '/index_all_urls', fields: [] },
  { name: 'Create URL Category Entries', url: '/create_url_category_entries', fields: [{ name: 'term', type: 'text' }] },
  { name: 'Create Embeddings from Moderation Result', url: '/create_embeddings_from_moderation_result', fields: [{ name: 'url', type: 'text' }] },
];

const AccordionComponent: React.FC<AccordionComponentProps> = ({ token }) => {
  return (
    <div>
      {endpoints.map((endpoint, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{endpoint.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormComponent endpoint={endpoint.url} fields={endpoint.fields} token={token} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionComponent;
